/* eslint-disable import/no-cycle */
/* eslint-disable wc/guard-super-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { html, unsafeCSS } from 'lit';
// import { LocalizeController } from '../../utilities/localize.js';
import { property, query } from 'lit/decorators.js';
import { watch } from '../base-element/directives/watch.directive';
import { BaseElement, customElement } from '../base-element';
// import ShoelaceElement from '../../internal/shoelace-element.js';
import { TreeItemWC } from '../tree-item/tree-item.wc';
import { clamp } from './clamp';
import treeStyles from './tree.scss?inline';
// import type { CSSResultGroup } from 'lit';

function syncCheckboxes(changedTreeItem: TreeItemWC, initialSync = false) {
  function syncParentItem(treeItem: TreeItemWC) {
    const children = treeItem.getChildrenItems({ includeDisabled: false });

    if (children.length) {
      const allChecked = children.every((item) => item.selected);
      const allUnchecked = children.every((item) => !item.selected);

      treeItem.selected = allChecked;
      // treeItem.indeterminate = !allChecked && !allUnchecked;
    }
  }

  function syncAncestors(treeItem: TreeItemWC) {
    const parentItem: TreeItemWC | null = treeItem.parentElement as TreeItemWC;

    if (TreeItemWC.isTreeItem(parentItem)) {
      syncParentItem(parentItem);
      syncAncestors(parentItem);
    }
  }

  function syncDescendants(treeItem: TreeItemWC) {
    for (const childItem of treeItem.getChildrenItems()) {
      childItem.selected = initialSync
        ? treeItem.selected || childItem.selected
        : !childItem.disabled && treeItem.selected;

      syncDescendants(childItem);
    }

    if (initialSync) {
      syncParentItem(treeItem);
    }
  }

  syncDescendants(changedTreeItem);
  syncAncestors(changedTreeItem);
}

/**
 * @summary Trees allow you to display a hierarchical list of selectable [tree items](/components/tree-item). Items with children can be expanded and collapsed as desired by the user.
 * @documentation https://shoelace.style/components/tree
 * @status stable
 * @since 2.0
 *
 * @event {{ selection: TreeItemWC[] }} pl-selection-change - Emitted when a tree item is selected or deselected.
 *
 * @slot - The default slot.
 * @slot expand-icon - The icon to show when the tree item is expanded. Works best with `<sl-icon>`.
 * @slot collapse-icon - The icon to show when the tree item is collapsed. Works best with `<sl-icon>`.
 *
 * @csspart base - The component's base wrapper.
 *
 * @cssproperty [--indent-size=var(--sl-spacing-medium)] - The size of the indentation for nested items.
 * @cssproperty [--indent-guide-color=var(--sl-color-neutral-200)] - The color of the indentation line.
 * @cssproperty [--indent-guide-offset=0] - The amount of vertical spacing to leave between the top and bottom of the
 *  indentation line's starting position.
 * @cssproperty [--indent-guide-style=solid] - The style of the indentation line, e.g. solid, dotted, dashed.
 * @cssproperty [--indent-guide-width=0] - The width of the indentation line.
 */
@customElement('ps-tree')
export class TreeWC extends BaseElement {
  static styles = unsafeCSS(treeStyles);

  @query('slot:not([name])') defaultSlot: HTMLSlotElement;

  @query('slot[name=expand-icon]') expandedIconSlot: HTMLSlotElement;

  @query('slot[name=collapse-icon]') collapsedIconSlot: HTMLSlotElement;

  /**
   * The selection behavior of the tree. Single selection allows only one node to be selected at a time. Multiple
   * displays checkboxes and allows more than one node to be selected. Leaf allows only leaf nodes to be selected.
   */
  @property() selection: 'single' | 'multiple' | 'leaf' = 'single';

  //
  // A collection of all the items in the tree, in the order they appear. The collection is live, meaning it is
  // automatically updated when the underlying document is changed.
  //
  private lastFocusedItem: TreeItemWC | null;

  // private readonly localize = new LocalizeController(this);

  private mutationObserver: MutationObserver;

  private clickTarget: TreeItemWC | null = null;

  constructor() {
    super();
    this.addEventListener('focusin', this.handleFocusIn);
    this.addEventListener('focusout', this.handleFocusOut);
    this.addEventListener('sl-lazy-change', this.handleSlotChange);
  }

  async connectedCallback() {
    super.connectedCallback();

    this.setAttribute('role', 'tree');
    this.setAttribute('tabindex', '0');

    await this.updateComplete;

    this.mutationObserver = new MutationObserver(this.handleTreeChanged);
    this.mutationObserver.observe(this, { childList: true, subtree: true });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.mutationObserver?.disconnect();
  }

  // Generates a clone of the expand icon element to use for each tree item
  private getExpandButtonIcon(status: 'expand' | 'collapse') {
    const slot =
      status === 'expand' ? this.expandedIconSlot : this.collapsedIconSlot;
    const icon = slot.assignedElements({ flatten: true })[0] as HTMLElement;

    // Clone it, remove ids, and slot it
    if (icon) {
      const clone = icon.cloneNode(true) as HTMLElement;
      [clone, ...clone.querySelectorAll('[id]')].forEach((el) =>
        el.removeAttribute('id')
      );
      clone.setAttribute('data-default', '');
      clone.slot = `${status}-icon`;

      return clone;
    }

    return null;
  }

  // Initializes new items by setting the `selectable` property and the expanded/collapsed icons if any
  private initTreeItem = (item: TreeItemWC) => {
    // item.selectable = this.selection === 'multiple';

    ['expand', 'collapse']
      .filter((status) => !!this.querySelector(`[slot="${status}-icon"]`))
      // @ts-ignore
      .forEach((status: 'expand' | 'collapse') => {
        const existingIcon = item.querySelector(`[slot="${status}-icon"]`);

        if (existingIcon === null) {
          // No separator exists, add one
          item.append(this.getExpandButtonIcon(status)!);
        } else if (existingIcon.hasAttribute('data-default')) {
          // A default separator exists, replace it
          existingIcon.replaceWith(this.getExpandButtonIcon(status)!);
        } else {
          // The user provided a custom icon, leave it alone
        }
      });
  };

  private handleTreeChanged = (mutations: MutationRecord[]) => {
    for (const mutation of mutations) {
      const addedNodes: TreeItemWC[] = [...mutation.addedNodes].filter(
        // @ts-ignore
        TreeItemWC.isTreeItem
      ) as TreeItemWC[];
      const removedNodes = [...mutation.removedNodes].filter(
        // @ts-ignore
        TreeItemWC.isTreeItem
      ) as TreeItemWC[];

      addedNodes.forEach(this.initTreeItem);

      if (this.lastFocusedItem && removedNodes.includes(this.lastFocusedItem)) {
        this.lastFocusedItem = null;
      }
    }
  };

  private selectItem(selectedItem: TreeItemWC) {
    const previousSelection = [...this.selectedItems];

    if (this.selection === 'multiple') {
      selectedItem.selected = !selectedItem.selected;
      // if (selectedItem.lazy) {
      //   selectedItem.expanded = true;
      // }
      syncCheckboxes(selectedItem);
    } else if (this.selection === 'single' || selectedItem.isLeaf) {
      const items = this.getAllTreeItems();
      for (const item of items) {
        item.selected = item === selectedItem;
      }
    } else if (this.selection === 'leaf') {
      selectedItem.expanded = !selectedItem.expanded;
    }

    const nextSelection = this.selectedItems;

    if (
      previousSelection.length !== nextSelection.length ||
      nextSelection.some((item) => !previousSelection.includes(item))
    ) {
      // Wait for the tree items' DOM to update before emitting
      Promise.all(nextSelection.map((el) => el.updateComplete)).then(() => {
        this.emit('pl-selection-change', {
          detail: { selection: nextSelection },
        });
      });
    }
  }

  private getAllTreeItems() {
    const a = this.querySelectorAll<TreeItemWC>('ps-tree-item');

    return [...a];
  }

  private focusItem(item?: TreeItemWC | null) {
    item?.focus();
  }

  private handleKeyDown(event: KeyboardEvent) {
    // Ignore key presses we aren't interested in
    if (
      ![
        'ArrowDown',
        'ArrowUp',
        'ArrowRight',
        'ArrowLeft',
        'Home',
        'End',
        'Enter',
        ' ',
      ].includes(event.key)
    ) {
      return;
    }

    // Ignore key presses when focus is inside a text field. This prevents the component from hijacking nested form
    // controls that exist inside tree items.
    if (
      event
        .composedPath()
        // @ts-ignore
        .some((el: HTMLElement) =>
          ['input', 'textarea'].includes(el?.tagName?.toLowerCase())
        )
    ) {
      return;
    }

    const items = this.getFocusableItems();

    const isLtr = this.matches(':dir(ltr)');
    const isRtl = this.matches(':dir(rtl)');

    if (items.length > 0) {
      event.preventDefault();
      event.stopPropagation();

      const activeItemIndex = items.findIndex((item) => item.matches(':focus'));
      const activeItem: TreeItemWC | undefined = items[activeItemIndex];

      const focusItemAt = (index: number) => {
        const item = items[clamp(index, 0, items.length - 1)];
        this.focusItem(item);
      };
      const toggleExpand = (expanded: boolean) => {
        activeItem.expanded = expanded;
      };

      if (event.key === 'ArrowDown') {
        // Moves focus to the next node that is focusable without opening or closing a node.
        focusItemAt(activeItemIndex + 1);
      } else if (event.key === 'ArrowUp') {
        // Moves focus to the next node that is focusable without opening or closing a node.
        focusItemAt(activeItemIndex - 1);
      } else if (
        (isLtr && event.key === 'ArrowRight') ||
        (isRtl && event.key === 'ArrowLeft')
      ) {
        //
        // When focus is on a closed node, opens the node; focus does not move.
        // When focus is on a open node, moves focus to the first child node.
        // When focus is on an end node (a tree item with no children), does nothing.
        //
        if (
          !activeItem ||
          activeItem.disabled ||
          activeItem.expanded ||
          activeItem.isLeaf
        ) {
          focusItemAt(activeItemIndex + 1);
        } else {
          toggleExpand(true);
        }
      } else if (
        (isLtr && event.key === 'ArrowLeft') ||
        (isRtl && event.key === 'ArrowRight')
      ) {
        //
        // When focus is on an open node, closes the node.
        // When focus is on a child node that is also either an end node or a closed node, moves focus to its parent node.
        // When focus is on a closed `tree`, does nothing.
        //
        if (
          !activeItem ||
          activeItem.disabled ||
          activeItem.isLeaf ||
          !activeItem.expanded
        ) {
          focusItemAt(activeItemIndex - 1);
        } else {
          toggleExpand(false);
        }
      } else if (event.key === 'Home') {
        // Moves focus to the first node in the tree without opening or closing a node.
        focusItemAt(0);
      } else if (event.key === 'End') {
        // Moves focus to the last node in the tree that is focusable without opening the node.
        focusItemAt(items.length - 1);
      } else if (event.key === 'Enter' || event.key === ' ') {
        // Selects the focused node.
        if (activeItem && !activeItem?.disabled) {
          this.selectItem(activeItem);
        }
      }
    }
  }

  private handleClick(event: MouseEvent) {
    const target = event.target as TreeItemWC;
    const treeItem = target.closest('ps-tree-item')! as TreeItemWC;

    let isExpandButton = false;
    event.composedPath().forEach((item) => {
      if (item instanceof HTMLElement) {
        if (item?.classList.contains('c-tree-item__expand-button')) {
          isExpandButton = true;
        }
      }
    });

    // const isExpandButton = event.composedPath().some((el) =>
    //   // @ts-ignore
    //   el?.classList.contains('tree-item__expand-button')
    // );

    // console.log(event.composedPath().some(p => ))
    //
    // Don't Do anything if there's no tree item, if it's disabled, or if the click doesn't match the initial target
    // from mousedown. The latter case prevents the user from starting a click on one item and ending it on another,
    // causing the parent node to collapse.
    //
    // See https://github.com/shoelace-style/shoelace/issues/1082
    //
    if (!treeItem || treeItem.disabled || target !== this.clickTarget) {
      return;
    }

    if (isExpandButton) {
      treeItem.expanded = !treeItem.expanded;
    } else {
      this.selectItem(treeItem);
    }
  }

  handleMouseDown(event: MouseEvent) {
    // Record the click target so we know which item the click initially targeted
    this.clickTarget = event.target as TreeItemWC;
  }

  private handleFocusOut = (event: FocusEvent) => {
    const relatedTarget = event.relatedTarget as HTMLElement;

    // If the element that got the focus is not in the tree
    if (!relatedTarget || !this.contains(relatedTarget)) {
      this.tabIndex = 0;
    }
  };

  private handleFocusIn = (event: FocusEvent) => {
    const target = event.target as TreeItemWC;

    // If the tree has been focused, move the focus to the last focused item
    if (event.target === this) {
      this.focusItem(this.lastFocusedItem || this.getAllTreeItems()[0]);
    }

    // If the target is a tree item, update the tabindex
    if (TreeItemWC.isTreeItem(target) && !target.disabled) {
      if (this.lastFocusedItem) {
        this.lastFocusedItem.tabIndex = -1;
      }
      this.lastFocusedItem = target;
      this.tabIndex = -1;

      target.tabIndex = 0;
    }
  };

  private handleSlotChange() {
    const items = this.getAllTreeItems();
    items.forEach(this.initTreeItem);
  }

  @watch('selection')
  async handleSelectionChange() {
    // const isSelectionMultiple = this.selection === 'multiple';
    const items = this.getAllTreeItems();

    this.setAttribute('aria-multiselectable', 'false');

    // for (const item of items) {
    //   item.selectable = false;
    // }

    // if (isSelectionMultiple) {
    //   await this.updateComplete;

    //   [...this.querySelectorAll(':scope > sl-tree-item')].forEach(
    //     (treeItem: TreeItemWC) => syncCheckboxes(treeItem, true)
    //   );
    // }
  }

  /** @internal Returns the list of tree items that are selected in the tree. */
  get selectedItems(): TreeItemWC[] {
    const items = this.getAllTreeItems();
    const isSelected = (item: TreeItemWC) => item.selected;

    return items.filter(isSelected);
  }

  /** @internal Gets focusable tree items in the tree. */
  getFocusableItems() {
    const items = this.getAllTreeItems();
    const collapsedItems = new Set();

    return items.filter((item) => {
      // Exclude disabled elements
      if (item.disabled) return false;

      // Exclude those whose parent is collapsed or loading
      const parent: TreeItemWC | null | undefined =
        item.parentElement?.closest('[role=treeitem]');
      if (parent && (!parent.expanded || collapsedItems.has(parent))) {
        collapsedItems.add(item);
      }

      return !collapsedItems.has(item);
    });
  }

  render() {
    return html`
      <div
        part="base"
        class="tree"
        @click=${this.handleClick}
        @keydown=${this.handleKeyDown}
        @mousedown=${this.handleMouseDown}
      >
        <slot @slotchange=${this.handleSlotChange}></slot>
        <span hidden aria-hidden="true"><slot name="expand-icon"></slot></span>
        <span hidden aria-hidden="true"
          ><slot name="collapse-icon"></slot
        ></span>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-tree': TreeWC;
  }
}
