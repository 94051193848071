import { PropertyValueMap, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit/static-html.js';
import { property, state, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { FormControlMixin } from '@open-wc/form-control';
import {
  customElement,
  FormControl,
  CheckableFormControl,
  FormControlController,
  watch,
} from '../base-element';
import { BufferWC } from '../buffer';
import '../buttons';
import '../icon/icon.wc';
import styles from './input.scss?inline';

@customElement('ps-input')
export class InputWC
  extends FormControlMixin(BufferWC)
  implements CheckableFormControl
{
  static styles = unsafeCSS(styles);

  constructor() {
    super();

    this.onInput = super.onInput.bind(this);
    this.updated = super.updated.bind(this);
  }

  // The following properties and methods aren't strictly required,
  // but browser-level form controls provide them. Providing them helps
  // ensure consistency with browser-provided controls.
  getForm(): HTMLFormElement | null {
    return this.FormControlController.getForm();
  }

  // without this, we don't get native-like form field submit, reset, or per-input-field validation automatically triggered
  private readonly FormControlController = new FormControlController(this);

  @query('.c-input__input') input: HTMLInputElement;

  /**
   * @method
   * @return {boolean} Returns true if internals's target element has no validity
   * problems; otherwise, returns false, fires an invalid event at the element, and (if
   * the event isn't canceled) reports the problem to the user.
   */
  reportValidity() {
    // this is how visible form field show up when a form tries to be submitted
    return this.input?.reportValidity();
  }

  @property({ type: Number }) min: number | string | undefined;

  @property({ type: Number }) max: number | string | undefined;

  /** The input's visual variant. */
  @property() variant: 'standard' | 'outlined' = 'standard';

  /** The input's size. */
  @property({ reflect: true }) size: 'small' | 'medium' = 'medium';

  /** The name of the input, submitted as a name/value pair with form data. */
  @property() name = '';

  /** The current value of the input, submitted as a name/value pair with form data. */
  @property() value = '';

  /** The default value of the form control. Primarily used for resetting the form control. */
  @property() defaultValue = '';

  /**
   * The type of input. Works the same as a native `<input>` element, but only a subset of types are supported. Defaults
   * to `text`.
   */
  @property({ reflect: true }) type:
    | 'hidden'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'radio'
    | 'tel'
    | 'text'
    | 'time'
    | 'url' = 'text';

  /** Placeholder text to show as a hint when the input is empty. */
  @property({ reflect: true }) placeholder?: string = '';

  /** Makes the input a required field. */
  @property({ type: Boolean, reflect: true }) required = false;

  /** A regular expression pattern to validate input against. */
  @property() pattern: string;

  /** The minimum length of input that will be considered valid. */
  @property({ type: Number }) minlength: number;

  /** The maximum length of input that will be considered valid. */
  @property({ type: Number }) maxlength: number;

  /** Disables the input. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /**
   * The Boolean attribute, when present, makes the element not mutable, meaning the user can not edit the control.
   */
  @property({ type: Boolean, reflect: true }) readonly = false;

  /** Determines whether or not the password is currently visible. Only applies to password input types. */
  @property({ attribute: 'password-visible', type: Boolean }) passwordVisible =
    false;

  /** The input's help text. */
  @property() helpText = '';

  /** The input's error state. */
  @property({ type: Boolean }) hasError = false;

  @watch('hasError')
  onHasErrorChange() {
    let validationMessage = '';

    if (this.hasError) {
      validationMessage = this.helpText ? this.helpText : 'Error';
    }

    this.setCustomValidity(validationMessage);
    this.FormControlController.updateValidity();

    this.emit('ps-error');
  }

  /**
   * Specifies what permission the browser has to provide assistance in filling out form field values. Refer to
   * [this page on MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) for available values.
   */
  @property() autocomplete: string;

  /** Indicates that the input should receive focus on page load. */
  @property({ type: Boolean }) autofocus: boolean;

  /** The input's data-testid. */
  @property({ reflect: true }) 'data-testid'?: string = 'wc-input';

  handlePasswordToggle(e: MouseEvent | KeyboardEvent) {
    if ('key' in e) {
      if (e?.key !== 'Enter') return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.passwordVisible = !this.passwordVisible;
  }

  @state() invalid = false;

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  // since the data-user-invalid attribute is added when input is invalid + error shown (via our base component's form.ts file),
  // we can bind to this to automatically display the input's error styles
  // @todo: consider refactoring to be a reactive property (what I'm doing with the new validationMode prop)
  @property({ type: Boolean, reflect: true }) 'data-user-invalid' = false;

  private handleInvalid(event: Event) {
    this.FormControlController.setValidity(false);
    this.FormControlController.emitInvalidEvent(event);
  }

  /** Checks for validity but does not show the browser's validation message. */
  checkValidity() {
    return this.input?.checkValidity();
  }

  setCustomValidity(message: string) {
    this.input?.setCustomValidity(message);
    // this.invalid = !this.checkValidity();

    this.FormControlController.updateValidity();
  }

  /** Gets the validity state object */
  get validity() {
    return this.input?.validity;
  }

  // this is how all form fields with validation errors get triggered when a form tries to be submitted
  firstUpdated(
    changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ): void {
    super.firstUpdated(changedProperties);
    // console.log('this.checkValidity', this.checkValidity());
    // this.invalid = !this.checkValidity();

    this.FormControlController.updateValidity();
  }

  /** without this, some re-renders might not fully reset the component back to it's original state
   * for example, a native form reset button might correctly clear out the field's input value but not clear out computed validation state,
   * resuting in the field's validation error not showing up when expected */
  @watch('value', { waitUntilFirstUpdate: true })
  async handleValueChange() {
    await this.updateComplete;
    // this.invalid = !this.checkValidity();

    this.FormControlController.updateValidity();
  }

  @watch('value')
  async onValueChange() {
    if (this.internalValue === undefined) {
      this.internalValue = this.value;
    }
  }

  private handleInput() {
    this.value = this.input.value;
    this.invalid = !this.checkValidity();
    // emitting the change event helps React form hooks expecting an input 'oninput' event to work as expected
    this.emit('input');
    this.onInput();
  }

  private handleKeyDown(event: KeyboardEvent) {
    const hasModifier =
      event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    // Pressing enter when focused on an input should submit the form like a native input, but we wait a tick before
    // submitting to allow users to cancel the keydown event if they need to
    if (event.key === 'Enter' && !hasModifier) {
      setTimeout(() => {
        //
        // When using an Input Method Editor (IME), pressing enter will cause the form to submit unexpectedly. One way
        // to check for this is to look at event.isComposing, which will be true when the IME is open.
        //
        // See https://github.com/shoelace-style/shoelace/pull/988
        //
        if (!event.defaultPrevented && !event?.isComposing) {
          this.FormControlController.submit();
        }
      }, 10);
    }
  }

  @state() hasFocus = false;

  private handleChange() {
    this.value = this.input.value;
    // emitting the change event helps React form hooks expecting an input 'onchange' event work as expected
    this.emit('change');
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('focus');
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('blur');
  }

  render() {
    return html`
      <div
        class=${classMap({
          'c-input': true,
          'c-input--error':
            this.hasError ||
            (this['data-user-invalid'] && this.validationMode === 'onChange'),
        })}
      >
        <!-- autocomplete type is correct, reported bug in lit-plugin -->
        <div class="c-input-wrapper">
          <input
            class=${classMap({
              'c-input__input': true,
              'c-input__input--disabled': this.disabled,
              'c-input__input--readonly': this.readonly,
              'c-input__input--has-value': !!this.value,
              [`c-input__input--variant-${this.variant}`]: this.variant,
              [`c-input__input--size-${this.size}`]: this.size,
            })}
            name=${ifDefined(this.name)}
            type=${this.type === 'password' && this.passwordVisible
              ? 'text'
              : this.type}
            pattern=${ifDefined(this.pattern)}
            min=${ifDefined(this.min)}
            max=${ifDefined(this.max)}
            minlength=${ifDefined(this.minlength)}
            maxlength=${ifDefined(this.maxlength)}
            placeholder=${ifDefined(this.placeholder)}
            .value=${live(this.internalValue)}
            ?disabled=${this.disabled}
            ?required=${this.required}
            ?readonly=${this.readonly}
            title=""
            autocomplete=${ifDefined(
              this.type === 'password' ? 'off' : this.autocomplete
            )}
            ?autofocus=${this.autofocus}
            @input=${this.handleInput}
            @invalid=${this.handleInvalid}
            @change=${this.handleChange}
            @focus=${this.handleFocus}
            @keydown=${this.handleKeyDown}
            @blur=${this.handleBlur}
          />
          <div class="c-input__line"></div>
          ${this.type === 'password'
            ? html`
                <span class="c-input__toggle-btn">
                  <ps-icon-button
                    name=${this.passwordVisible ? 'hide' : 'show'}
                    size="small"
                    variant="tertiary"
                    @click=${this.handlePasswordToggle}
                    @keydown=${this.handlePasswordToggle}
                  >
                  </ps-icon-button>
                </span>
              `
            : ''}
        </div>
        ${this.helpText || this.slotted('extra')
          ? html`
              <div class="c-input__footer">
                <div class="c-input__help-text">
                  ${this.hasError
                    ? html`<ps-icon name="warning" size="auto"></ps-icon>`
                    : null}
                  ${!this.hasError && this.helpText
                    ? html`<ps-icon name="info" size="auto"></ps-icon>`
                    : null}
                  <div>${this.helpText}</div>
                </div>
                <div class="c-input__extra">
                  <slot name="extra"></slot>
                </div>
              </div>
            `
          : ''}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-input': InputWC;
  }
}
