/* eslint-disable no-console */
import { html, nothing, PropertyValueMap } from 'lit';
import { property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import {
  Product,
  Project,
  UserProductRoles,
} from '@pypestream/api-services/urql.limited-access.candidate';
import { ProductName } from '@pypestream/api-services';
import compact from 'lodash.compact';
import type { MenuItemWC } from '../../components';

import { logos } from '../../assets/logos';
import megaMenuEmptyStateIcon from '../../assets/mega-menu-empty-state.svg?url';
import {
  BaseElement,
  customElement,
  watch,
} from '../../components/base-element';
import { ScrollbarWC } from '../../components/scrollbar/scrollbar.wc';
import { generateBaseURLsToTools } from './utils';
import { scrollIntoView } from '../../components/base-element/utilities/scroll';
import { SmartContext } from '../xstate/smart.xstate-utils';
import { smartService } from '../xstate/smart.xstate';

type CommonProduct = Product & {
  label?: string;
  logo: keyof typeof logos;
  url?: string;
  disabled?: boolean;
  integrated?: boolean;
  index?: number;
  conditional?: boolean;
};

type CommonProject = Project & {
  availableProducts?: CommonProduct[];
  hasAvailableProduct?: boolean;
};

interface MegaMenuState {
  projects: CommonProject[];
  selectedProject?: CommonProject;
}

@customElement('ps-mega-menu')
export class MegaMenuWC extends BaseElement {
  context: SmartContext;

  @property({ reflect: false }) app: ProductName;

  @property({ reflect: false }) project?: string;

  @property({ reflect: false, type: Boolean }) hideGoHome?: boolean = false;

  @state() private megaMenuState: MegaMenuState = {
    projects: [],
  };

  @state() onProjectSelect: (projectId: string | undefined) => void;

  @property() onManagerToolClick?: (route: string) => void;

  @state() private chatLink: string = '';

  @watch('project', { waitUntilFirstUpdate: true })
  handleResetSelectedProject() {
    this.megaMenuState.selectedProject = this.megaMenuState.projects.find(
      ({ id }) => id === this.project
    );
  }

  private chatTimerId: NodeJS.Timeout;

  constructor() {
    super();

    this.init = this.init.bind(this);
  }

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      this.context = smartState.context;
      this.init();
    });

    const check = () => {
      const chatHolder =
        document.getElementsByClassName('woot-widget-holder')?.[0];
      const iframeUrl = chatHolder?.firstElementChild?.getAttribute('src');

      if (!iframeUrl) {
        this.chatTimerId = setTimeout(check, 100);
      } else {
        this.chatLink = iframeUrl;
        clearInterval(this.chatTimerId);
      }
    };

    this.chatTimerId = setTimeout(check, 100);
  }

  protected updated(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (changedProperties.has('env') || changedProperties.has('org')) {
      this.init();
    }
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();

    clearTimeout(this.chatTimerId);
  }

  init() {
    const { projects, userProjects, userProductRoles, allProducts } =
      this.context;

    const allProjects = compact(
      projects
        ?.map(({ projectId, ...rest }) => ({
          ...rest,
          id: projectId,
        }))
        .filter((project) => userProjects.includes(project.id))
    );

    if (userProductRoles && allProducts) {
      this.initializeProjects(allProjects, userProductRoles, allProducts);
    }
  }

  initializeProjects(
    projects: Project[],
    productRoles: UserProductRoles,
    allTools: Product[]
  ) {
    const commonProjects: CommonProject[] = projects;
    commonProjects.forEach((project, index) => {
      commonProjects[index].availableProducts = this.getProducts(
        project,
        productRoles,
        allTools,
        this.context
      );
      commonProjects[index].hasAvailableProduct =
        (commonProjects[index].availableProducts || []).filter(
          (product) => !product.disabled
        ).length > 1;
    });
    this.megaMenuState.projects = commonProjects;
    this.megaMenuState.selectedProject =
      this.megaMenuState.projects.length > 0
        ? this.megaMenuState.projects.find(({ id }) => id === this.project)
        : undefined;
    this.requestUpdate();
    this.handleClickMegaMenuIcon();
  }

  // Auto-scroll to the selected item.
  // eslint-disable-next-line class-methods-use-this
  handleClickMegaMenuIcon() {
    setTimeout(() => {
      // target the popover itself vs whatever is initially rendered WITHIN this component --> needs to work with new portal placement
      const popoverElem = document.querySelector('#megamenu-popover');

      if (popoverElem) {
        const selectedItems =
          popoverElem.querySelectorAll<MenuItemWC>('ps-menu-item');
        const selectedItem = Array.from(selectedItems).filter(
          (item) => item.selected === true
        );

        const scrollBarContainer = popoverElem.querySelector(
          '.c-mega-menu__scrollbar'
        ) as ScrollbarWC;

        const scrollableContainer =
          scrollBarContainer?.simplebar?.getScrollElement();

        if (selectedItem[0] && scrollableContainer) {
          scrollIntoView(
            selectedItem[0],
            scrollableContainer,
            'vertical',
            'auto'
          );
        }
      }
    }, 0);
  }

  // eslint-disable-next-line class-methods-use-this, max-params
  getProducts(
    project: Project,
    productRoles: UserProductRoles,
    allTools: Product[],
    ctx: SmartContext
  ) {
    const projectId = project.id;
    const { featureFlags } = ctx;
    const agentAssistAccountId = featureFlags?.managerProjectIdForContactCenter
      ? project.accountId
      : project?.agentAssistAccountId;
    const productIds = allTools.reduce(
      (acc, { name, id }) => ({ ...acc, [`${name}`]: id }),
      {} as Record<ProductName, string>
    );
    const projectProducts =
      project?.projectProductSettings?.map(({ productId }) => productId) || [];
    const hasAgentAssistRoles = productRoles.agentAssistRolesSource?.some(
      ({ project: p }) => p?.id === projectId
    );
    const hasAnalyticsRoles = productRoles.analyticsRolesSource?.some(
      ({ project: p }) => p?.id === projectId
    );
    const productBaseURLs = generateBaseURLsToTools();
    const managerURL =
      this.onManagerToolClick && window.location.host.includes('manager')
        ? `/organization/${project.accountId}/projects/${projectId}`
        : `${productBaseURLs.Manager}/organization/${project.accountId}/projects/${projectId}`;
    const studioURL = `${productBaseURLs.Studio}/${projectId}`;
    const contactCenterUrl =
      hasAgentAssistRoles && agentAssistAccountId
        ? `${productBaseURLs.ContactCenter}/app/accounts/${agentAssistAccountId}/dashboard`
        : undefined;
    const analyticsURL = `${productBaseURLs.Analytics}/api/projects/${projectId}/change`;

    const commonProductsProps: CommonProduct[] = [
      {
        name: ProductName.Organization,
        label: 'Manager',
        logo: 'org',
        url: managerURL,
        disabled: false,
        // It means Manager is always available.
        integrated: true,
        index: 1,
      },
      {
        name: 'Studio' as ProductName,
        label: 'Studio',
        logo: 'studio',
        url: studioURL,
        conditional: true,
        // @todo: define disabled condition for Studio
        disabled: true,
        index: 4,
      },
      {
        name: ProductName.AgentAssist,
        label: 'Contact Center',
        logo: 'agentAssist',
        url: contactCenterUrl,
        conditional: true,
        disabled: !hasAgentAssistRoles,
        index: 2,
      },
      {
        name: ProductName.Analytics,
        label: 'Analytics',
        logo: 'analytics',
        // @todo: generate correct link for Analytics when service will be ready
        url: analyticsURL,
        conditional: true,
        disabled: !hasAnalyticsRoles,
        index: 3,
      },
    ];
    const products: CommonProduct[] = commonProductsProps
      .map((cProduct) => {
        const productId = cProduct.name
          ? productIds?.[cProduct.name] || ''
          : '';
        const integrated = projectId
          ? projectProducts?.includes(productId)
          : false;
        const isDisabled = cProduct.conditional
          ? cProduct.disabled || !integrated
          : cProduct.disabled;

        return {
          ...cProduct,
          id: productId,
          disabled: isDisabled && cProduct.name !== ProductName.Organization,
          integrated: cProduct.integrated || integrated,
        };
      })
      .filter((cProduct) => cProduct.integrated);

    return products;
  }

  handleManagerToolClick(route?: string) {
    if (!route || !this.onManagerToolClick) return;
    // To avoid bug that mega menu still open after clicking and during route reloading
    document
      .querySelector('ps-universal-nav')
      ?.shadowRoot?.querySelector('ps-mega-menu')
      ?.shadowRoot?.querySelector('#megamenu-trigger')
      ?.dispatchEvent(new Event('click', { bubbles: true }));
    this.onManagerToolClick(route);
  }

  renderTools() {
    return html`
      <ps-grid>
        ${map(
          this.megaMenuState.selectedProject?.availableProducts || [],
          (product) => html`
            <ps-grid-cell xsmall="6" medium="4">
              <ps-card
                size="large"
                interactive
                ?disabled=${product.disabled}
                .href=${product.name === ProductName.Organization &&
                this.onManagerToolClick
                  ? undefined
                  : product.url}
                @click=${product.name === ProductName.Organization &&
                this.onManagerToolClick
                  ? () => this.handleManagerToolClick(product.url)
                  : undefined}
              >
                <ps-stack
                  gutter="xsmall"
                  direction="column"
                  alignItems="center"
                >
                  <ps-image
                    src=${logos[product.logo]}
                    width="40px"
                    height="40px"
                  ></ps-image>
                  <ps-text-body size="small" truncate
                    >${product.label}</ps-text-body
                  >
                </ps-stack>
              </ps-card>
            </ps-grid-cell>
          `
        )}
        ${!this.megaMenuState.selectedProject
          ? html`
              <ps-grid-cell xsmall="12" medium="12">
                <ps-spacer size="xlarge"></ps-spacer>
                <ps-stack gutter="large" direction="column" alignItems="center">
                  <ps-image
                    src=${megaMenuEmptyStateIcon}
                    width="128px"
                    height="128px"
                  ></ps-image>
                  <ps-text
                    size="3xsmall"
                    variant="tertiary"
                    font-weight="medium"
                    >Select a
                    <span class="c-mega-menu__project-word-color">Project</span>
                    from the menu to get started</ps-text
                  >
                </ps-stack>
              </ps-grid-cell>
            `
          : nothing}
      </ps-grid>
    `;
  }

  handleProjectSelect(event: MouseEvent, project: CommonProject) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const selectedProject =
      this.megaMenuState.selectedProject?.id === project.id
        ? undefined
        : project;

    this.megaMenuState.selectedProject = selectedProject;
    this.requestUpdate();
    this.onProjectSelect?.(selectedProject?.id);
  }

  private _handleGoHome = () => {
    window.location.href = `${generateBaseURLsToTools().Manager}${this.context.accountId ? `/organization/${this.context.accountId}` : ''}`;
  };

  renderProjects() {
    const { projects, selectedProject } = this.megaMenuState;

    if (projects.length) {
      return html`
        ${map(
          projects,
          (project) => html`
            <ps-menu-item
              ?selected=${selectedProject?.id === project.id}
              @click=${(event: MouseEvent) => {
                const isToolAvalable = project.availableProducts?.some(
                  ({ name, disabled }) => name === this.app && !disabled
                );

                if (!isToolAvalable) {
                  this._handleGoHome();
                  return;
                }

                this.handleProjectSelect(event, project);
              }}
            >
              ${project?.name}
            </ps-menu-item>
          `
        )}
      `;
    }

    return html`<ps-menu-item disabled>No available projects</ps-menu-item>`;
  }

  // eslint-disable-next-line class-methods-use-this
  renderGoToHome() {
    if (this.hideGoHome) return nothing;

    return html`
      <ps-menu-item variant="ghost" @click=${this._handleGoHome}>
        Go Home
        <ps-icon name="arrow-left" slot="prefix"></ps-icon>
      </ps-menu-item>
    `;
  }

  startChat() {
    if (!this.chatLink) return;

    window.open(
      this.chatLink,
      '_blank',
      'width=600,height=400,right=0,bottom=0,toolbar=no,status=no,menubar=no,location=no'
    );
  }

  protected render() {
    return html`
      <ps-icon-button
        name="grid-menu"
        id="megamenu-trigger"
        @click=${this.handleClickMegaMenuIcon}
      ></ps-icon-button>
      <ps-popover
        id="megamenu-popover"
        trigger="megamenu-trigger"
        placement="bottom-start"
        width="auto"
      >
        <style>
          .c-mega-menu {
            width: 719px;
            max-width: calc(100vw - 82px);
          }

          .c-mega-menu__menu-header {
            --ps-theme-text-secondary-color: #8573e7;
          }

          .c-mega-menu__scrollbar-wrapper::after {
            content: '';
            position: absolute;
            display: block;
            left: -12px;
            right: -12px;
            bottom: 0;
            border-bottom: 1px solid #dfe1e5;
          }

          .c-mega-menu__project-word-color {
            color: #62697c;
          }

          @media (min-width: 768px) {
            .c-mega-menu {
              padding: 16px 0;
            }

            .c-mega-menu__scrollbar-wrapper {
              margin-right: -12px;
              height: 100%;
              max-height: 288px;
              display: flex;
              flex-direction: column;
            }

            .c-mega-menu__menu {
              padding-right: 12px;
            }

            .c-mega-menu__scrollbar-wrapper::after {
              top: -28px;
              right: 0;
              left: auto;
              bottom: -28px;
              border-bottom: 0;
              border-right: 1px solid #dfe1e5;
            }

            .c-mega-menu__content {
              display: flex;
              flex-direction: column;
              height: 100%;
              padding-left: 28px;
              padding-right: 16px;
            }

            .c-mega-menu__helper {
              margin-top: auto;
            }

            .c-mega-menu__callout {
              padding-left: 0.75rem;
            }
          }
        </style>
        <div class="c-mega-menu">
          <ps-grid gutter="large" rowGutter="large" alignItems="stretch">
            <ps-grid-cell xsmall="12" medium="4">
              <div class="c-mega-menu__scrollbar-wrapper">
                <ps-scrollbar
                  force-visible
                  disable-auto-hide
                  class="c-mega-menu__scrollbar"
                >
                  <ps-menu class="c-mega-menu__menu">
                    <ps-text
                      class="c-mega-menu__menu-header"
                      size="3xsmall"
                      variant="secondary"
                      font-weight="medium"
                      >Projects</ps-text
                    >
                    ${this.renderProjects()}
                  </ps-menu>
                </ps-scrollbar>
                ${this.renderGoToHome()}
              </div>
            </ps-grid-cell>
            <ps-grid-cell xsmall="12" medium="8">
              <div class="c-mega-menu__content">
                <ps-text size="3xsmall" variant="tertiary" font-weight="medium"
                  >Shortcuts</ps-text
                >
                <ps-spacer></ps-spacer>
                ${this.renderTools()}
                ${this.chatLink
                  ? html`
                      <ps-spacer size="2xlarge"></ps-spacer>
                      <ps-spacer size="xlarge"></ps-spacer>
                      <div class="c-mega-menu__helper">
                        <ps-callout theme="light" size="small">
                          <div class="c-mega-menu__callout">
                            <ps-text
                              size="3xsmall"
                              variant="secondary"
                              font-weight="medium"
                            >
                              Need help with something? Contact a specialist
                              <a href="#" @click=${this.startChat}>here</a>.
                            </ps-text>
                          </div>
                        </ps-callout>
                      </div>
                    `
                  : nothing}
              </div>
            </ps-grid-cell>
          </ps-grid>
        </div>
      </ps-popover>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-mega-menu': MegaMenuWC;
  }

  enum PSElementTagNameMap {
    'ps-mega-menu' = 'ps-mega-menu',
  }
}
